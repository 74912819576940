



























































import { EventSession } from "@/models/event-session.model";
import Vuetable from "vuetable-2";
import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";

const eventSessionModule = namespace("EventSessions");

@Component({ components: { Vuetable } })
export default class WorkkshopDetail extends Vue {
  isLoading = false;
  fields = [
    { name: "active", sortField: "active" },
    { name: "title", sortField: "title" },
    { name: "start" },
    { name: "url", sortField: "url" },
    { name: "actions", sortField: "Actions" },
  ];

  @eventSessionModule.Getter("all")
  eventSessions!: EventSession[];

  @eventSessionModule.Action("fetchAll")
  fetchAll!: () => Promise<EventSession[]>;

  @eventSessionModule.Action("create")
  create!: (eventSession: EventSession) => Promise<EventSession>;

  @eventSessionModule.Action("delete")
  delete!: (eventSession: string) => Promise<EventSession>;

  get locale() {
    return this.$root.$i18n.locale;
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchAll();
    this.isLoading = false;
  }

  doCreate() {
    this.$router.push({ name: "event-session-create" });
  }

  async doCopy(eventSession: EventSession) {
    const { id, ...rest } = eventSession;
    const newEventSession = await this.create(rest as any);
    this.doEdit(newEventSession);
  }

  doEdit(eventSession: EventSession) {
    this.$router.push({
      name: "event-session-detail",
      params: { id: eventSession.id },
    });
  }

  async doDelete(eventSession: EventSession) {
    this.isLoading = true;
    const isConfirmed = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove ${eventSession.title}?`,
      {
        title: "Confirm delete",
        okVariant: "danger",
        centered: true,
      }
    );
    if (isConfirmed) {
      await this.delete(eventSession.id);
    }
    this.isLoading = false;
    this.doFetchAll();
  }

  created() {
    this.doFetchAll();
  }
}
